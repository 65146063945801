@mixin on-tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin on-desktop {
  @media (min-width: 1060px) {
    @content;
  }
}

.main {
  &::before {
    content: '';
    width: 100%;
    height: 3px;
    display: block;
    background-color: #ecbc42;
  }

  &__container {
    &::after {
      content: '';
      width: 100%;
      height: 3px;
      display: block;
      background-color: #ecbc42;
    }
  }
}

// .umov {
//   display: flex;

//   &__content {
//     margin: auto;
//     display: flex;
//     flex-direction: column;
//   }

//   &__title {
//     font-size: 34px;

//     &::before {
//       position: relative;
//       top: -2px;
//       content: '';
//       width: 8%;
//       height: 5px;
//       display: block;
//       background-color: #ecbc42;
//       border-radius: 5px;
//     }
//   }

//   &__info {
//     display: flex;
//     gap: 20px;
//     width: 500px;
//     justify-content: space-between;
//   }

//   &__item {
//     width: 230px;
//     align-items: center;
//   }

//   &__ride {
//     position: relative;
//     left: 0;
//     height: 100vh;
//   }
// }

.umov {
  display: flex;

  &__content {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    font-size: 25px;
    width: 230px;
    margin-top: 50px;

    @include on-tablet {
      font-size: 35px;
      width: 330px;
    }

    &::before {
      position: relative;
      top: -2px;
      content: '';
      width: 15%;
      height: 5px;
      display: block;
      background-color: #ecbc42;
      border-radius: 5px;
    }
  }

  &__whyme {
    @include on-tablet {
      font-size: 20px;
    }
  }

  &__info {
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
  }

  &__item {
    width: 130px;
    align-items: center;
    font-size: 13px;
    margin-block: 20px;
    position: relative;

    @include on-tablet {
      font-size: 15px;
      width: 150px;
    }
    
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: -7px;
      top: -2px;
      width: 40px;
      height: 40px;
      background-image: url(../../image/icon/before-icon.png);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &__ride {
    position: relative;
    display: none;
    height: 100vh;

    @include on-tablet {
      display: block;
      width: 300px;
      object-fit: cover;
      object-position: right;
    }

    @include on-desktop {
      width: 500px;
    }
  }
}

.vigoda {
  background-color: #ecbc42;
  color: black;
  text-decoration: none;
  text-align: center;
  padding: 14px;
  width: 150px;
  margin-block: 50px;
}

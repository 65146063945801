@mixin on-tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin on-desktop {
  @media (min-width: 1060px) {
    @content;
  }
}

.abaut {
  padding: 50px 20px;
  max-width: 400px;
  margin-inline: auto;

  @include on-tablet {
    max-width: 500px;
  }

  @include on-desktop {
    max-width: 600px;
  }

  &__title {
    margin: 0;
    margin-bottom: 25px;
    font-size: 30px;

    @include on-tablet {
      font-size: 50px;
    }

    @include on-desktop {
      font-size: 60px;
    }
  }

  &__info {
    margin: 0;

    @include on-tablet {
      font-size: 20px;
    }

    @include on-desktop {
      font-size: 30px;
    }
  }
}
